import React from 'react';
import { useRequest } from 'hooks';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import HomePopUpRocket from 'assets/images/Home-Pop-Up-Rocket.png';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import AppHeader from 'components/header';
// import AppFooter from 'components/footer';
import AppFooter from 'components/footer-new';
import ModuleRouter from 'router/module';
// import ChatbotComponent from 'chatbot';
import SessionTimeout from 'components/session';
import CloseIcon from '@mui/icons-material/Close';
import { Button, CircularProgress, Stack, Typography, Box, Alert, Dialog, DialogContent, IconButton, DialogActions } from '@mui/material';
import { isRIEnabled, isReadOnlyUser, isCMPBetaUser, isCMPAdmin, showAdminTiles, isPlatformUser, showPlatformAdminTiles } from 'utils';
import { useLocation } from 'react-router-dom';
import WelcomeDialog from 'components/welcome-popup';
import ScrollToTopOnNavigation from 'components/ScrollToTopNavigation';
import { menuItems as MainNavigation } from 'utils/menuItems';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { platformManaged } from 'pages/reference-implementation/provisioning/RI001/utils/constant';
import { platformManaged as platformManagedEiap } from 'pages/reference-implementation/provisioning/RI003/utils/constant';
import { cmpRouteState, catalogs, riCatalogs, cmpSectors } from 'store';
import { RiCatalogsMapping } from 'utils/RiCatalogsMapping';

const AppWrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    background: '#F8F8F8',
});
// const DevXBanner = styled(Box)({
//     display: 'flex',
//     backgroundColor: '#F99C24 !important',
//     padding: '10px',
//     fontSize: '14px',
// });
const DevXBannerLg = styled(Box)({
    display: 'flex',
    backgroundColor: '#F99C24 !important',
    padding: '13px 1px 13px 1px',
    fontSize: '22px',
    textAlign: 'center',
    justifyContent: 'space-evenly',
    width: '100%',
    fontWeight: 'bold',
    flexDirection: 'row',
    // height: '15%',
    alignItems: 'center',
});
const CIconButton = styled(IconButton)(() => ({
    position: 'absolute',
    left: '92%',
    top: '2%',
    backgroundColor: 'white',
    color: 'gray',
    height: 33,
    width: 33,
    '&:hover': {
        backgroundColor: 'white',
    },
}));

function withAccessControl(BaseComponent) {
    return function CheckUserAccess() {
        const { isLoading, isError, data, refetch } = useRequest({ key: 'checkAuthUser', url: endPoints.user.auth });

        React.useEffect(() => {
            if (data && data.isSuccess) {
                localStorage.setItem('accessToken', data?.data?.accessToken);
                localStorage.setItem('pages', JSON.stringify(data?.data?.authorizedTo));
            }
        }, [data]);

        if (isLoading && !isError) {
            return (
                <Stack height="100%" direction="column" alignItems="center" justifyContent="center" spacing={1}>
                    <CircularProgress size={24} disableShrink />
                    <Typography sx={{ fontSize: 16, fontFamily: 'stolzl' }}>Checking User Access...</Typography>
                </Stack>
            );
        }

        if (!isLoading && isError) {
            return (
                <Box display="flex" height="100%" alignItems="center" justifyContent="center">
                    <Box display="flex" flexDirection="column" height="100%" width="500px" alignItems="center" justifyContent="center">
                        <img width={160} src={HomePopUpRocket} alt="" />
                        <Typography sx={{ fontSize: 16, fontFamily: 'stolzl' }}>
                            Currently unable to fetch your data, Kindly click on the retry button below in order to try again.
                        </Typography>
                        <Button
                            sx={{ my: 1, mx: 2, height: 35, textTransform: 'none', padding: 1, backgroundColor: '#0047BA' }}
                            variant="contained"
                            onClick={() => refetch()}
                        >
                            Retry
                        </Button>
                    </Box>
                </Box>
            );
        }
        return <BaseComponent />;
    };
}

function AppLayout() {
    const location = useLocation();
    const [previousLocation, setPreviousLocation] = React.useState(location);
    const [open, setOpen] = React.useState(false);
    const layoutRef = React.useRef(null);
    // const setCurrentLayoutScroll = useSetRecoilState(currentLayoutScroll);
    const setCatalogs = useSetRecoilState(catalogs);
    const setRiCatalogs = useSetRecoilState(riCatalogs);
    const [sectorOptions, setSectorOptions] = useRecoilState(cmpSectors);
    const [, setCmpRoute] = useRecoilState(cmpRouteState);
    const [isScrolling, setIsScrolling] = React.useState(false);
    const [isHovered, setIsHovered] = React.useState(false);
    const [devxBanner, setDevxBanner] = React.useState(true);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    const catalogsApi = useMutation(() =>
        axios.get(endPoints.catalog.allList, {
            headers: {
                Cookie: '',
            },
        })
    );
    // const riCatalogApi = useMutation(() => axios.get(endPoints.riServices.Jwt.catalogs));
    const riCatalogApi = useMutation(() => axios.get(endPoints.riServices.Jwt.catalogsInternal));

    const vmsectorApi = useMutation(() => axios.get(`${endPoints.ssp.vm.sector}`));
    const RIEdapsectorApi = useMutation(() => axios.get(`${endPoints.riServices.COMMON.sectors}?platformManaged=${platformManaged}`));
    const RIEiapsectorApi = useMutation(() => axios.get(`${endPoints.riServices.COMMON.sectors}?platformManaged=${platformManagedEiap}`));

    React.useEffect(() => {
        catalogsApi?.mutate();
        vmsectorApi.mutate();
        if (isRIEnabled()) riCatalogApi?.mutate();
    }, []);

    React.useEffect(() => {
        if (riCatalogApi?.data?.data) {
            const cmpRiCatalogs = riCatalogApi?.data?.data?.map((catalog) => ({
                ...catalog,
                cloudProvider: 'Cloud Marketplace',
                ...RiCatalogsMapping?.find((item) => item?.['reference-implementation-id'] === catalog?.['reference-implementation-id']),
            }));
            setRiCatalogs(
                isCMPAdmin() ? cmpRiCatalogs : cmpRiCatalogs?.filter((catalog) => !showAdminTiles?.includes(catalog?.['reference-implementation-id']))
            );
            // setRiCatalogs(modifiedCatalogs);
        }
    }, [riCatalogApi?.isSuccess]);

    React.useEffect(() => {
        if (catalogsApi?.data?.data?.catalogs) {
            const catalogArray = catalogsApi?.data?.data?.catalogs?.map((item) => ({ ...item, tags: item?.tags?.Catalog?.split(',') }));
            setCatalogs(
                isCMPAdmin() || isPlatformUser()
                    ? catalogArray
                    : catalogArray?.filter((catalog) => !showPlatformAdminTiles?.includes(catalog?.catalogDefinitionId))
            );
        }
    }, [catalogsApi?.isSuccess]);
    React.useEffect(() => {
        if (vmsectorApi?.isError && !vmsectorApi?.isLoading) {
            RIEdapsectorApi?.mutate();
        }
    }, [vmsectorApi?.isError]);
    React.useEffect(() => {
        if (vmsectorApi?.isSuccess && vmsectorApi?.data?.data?.sectors) {
            const vmsectorApiOptions = vmsectorApi?.data?.data?.sectors.map((app) => ({ label: app.name, value: app.name, data: app }));
            const data = [...sectorOptions, ...vmsectorApiOptions];
            setSectorOptions([...new Map(data.map((item) => [item.label, item])).values()]);
            RIEdapsectorApi?.mutate();
        }
    }, [vmsectorApi?.isSuccess]);
    React.useEffect(() => {
        if (RIEdapsectorApi?.isError && !RIEdapsectorApi?.isLoading) {
            RIEiapsectorApi?.mutate();
        }
    }, [RIEdapsectorApi?.isError]);

    React.useEffect(() => {
        if (RIEdapsectorApi?.isSuccess && RIEdapsectorApi?.data?.data) {
            const RIEdapsectorApiOptions = RIEdapsectorApi?.data?.data?.map((app) => ({
                label: app.sectorDisplayName,
                value: app.sectorDisplayName,
                data: app,
            }));
            const data = [...sectorOptions, ...RIEdapsectorApiOptions];
            setSectorOptions([...new Map(data.map((item) => [item.label, item])).values()]);
            RIEiapsectorApi?.mutate();
        }
    }, [RIEdapsectorApi?.isSuccess]);

    React.useEffect(() => {
        if (RIEiapsectorApi?.isSuccess && RIEiapsectorApi?.data?.data) {
            const RIEiapsectorApiOptions = RIEiapsectorApi?.data?.data?.map((app) => ({
                label: app.sectorDisplayName,
                value: app.sectorDisplayName,
                data: app,
            }));
            const data = [...sectorOptions, ...RIEiapsectorApiOptions];
            setSectorOptions([...new Map(data.map((item) => [item.label, item])).values()]);
        }
    }, [RIEiapsectorApi?.isSuccess]);

    React.useEffect(() => {
        const setLocation = async () => {
            await setCmpRoute({ previousLocation: previousLocation?.pathname, currentLocation: location?.pathname });
        };
        const setPrevLocation = async (locationPath) => {
            await setPreviousLocation(locationPath);
        };
        setLocation();
        setPrevLocation(location);
    }, [location]);

    React.useEffect(() => {
        const newUser = localStorage.getItem('newUser');
        if (newUser === undefined || newUser === null) {
            setOpen(true);
            localStorage.setItem('newUser', false);
        } else {
            setOpen(false);
        }
        const element = document.getElementById('rwc');
        if (element) {
            if (isCMPBetaUser()) {
                element.classList.remove('rwc-hide');
            } else {
                element.classList.add('rwc-hide');
            }
        }
    }, []);

    // React.useEffect(() => {
    //     const handleScroll = () => {
    //         if (layoutRef.current) {
    //             setCurrentLayoutScroll(layoutRef.current.scrollTop);
    //         }
    //     };
    //     layoutRef.current.addEventListener('scroll', handleScroll);
    //     return () => {
    //         layoutRef.current.removeEventListener('scroll', handleScroll);
    //     };
    // }, []);

    const handleClose = () => {
        localStorage.setItem('newUser', false);
        setOpen(false);
    };
    const getWidth = (Hovered) => {
        let barWidth = '';
        if (Hovered) {
            barWidth = '10px';
        } else if (location?.pathname?.includes('/dashboard')) {
            barWidth = '10px';
        } else {
            barWidth = '0px';
        }
        return barWidth;
    };
    const CustomScrollbar = ({ Hovered }) => {
        return (
            <style>
                {`
              .scrollable::-webkit-scrollbar {
                width: ${getWidth(Hovered)};
              }
      
              .scrollable::-webkit-scrollbar-thumb {
                background-color: ${Hovered ? '#888' : '#888'};
              }
              .scrollable::-webkit-scrollbar:active {
                width: 10px;
              }
              .scrollable::-webkit-scrollbar:focus {
                width: 10px;
              }
            `}
            </style>
        );
    };
    CustomScrollbar.propTypes = {
        Hovered: PropTypes.bool,
    };
    CustomScrollbar.defaultProps = {
        Hovered: false,
    };

    const handleScroll = () => {
        setIsScrolling(true);
        const layoutRef1 = setTimeout(() => {
            setIsScrolling(false);
        }, 10000); // Adjust the delay as needed
        clearTimeout(layoutRef1.current);
    };
    return (
        <SessionTimeout>
            <AppWrapper disablegutters="true" data-testid="app-wrapper">
                <AppHeader menus={MainNavigation} />
                {/* {location.pathname === '/' ? ( */}
                {devxBanner && (
                    <DevXBannerLg>
                        <Box width="92%">
                            There is a new version of CMP available in DevX - Rebranded as DevX Infra.Click &nbsp;
                            <a href={process.env.REACT_APP_DEVX_INFRA_URL} target="_blank" rel="noopener noreferrer">
                                <b>here</b>
                            </a>
                            &nbsp; to explore.
                        </Box>
                        <Box width="8%">
                            <CloseIcon
                                sx={{
                                    marginTop: '7px',
                                    height: 20,
                                    width: 20,
                                    '&:hover': {
                                        backgroundColor: 'white',
                                        borderRadius: '50%',
                                        cursor: 'pointer',
                                    },
                                }}
                                onClick={() => setDevxBanner(false)}
                            />
                        </Box>
                    </DevXBannerLg>
                )}
                {/* ) : (
                    <DevXBanner>
                        <Box sx={{ marginLeft: '22px' }}>
                            There is a new version of CMP available in DevX - Rebranded as DevX Infra. Click &nbsp;
                            <a href={process.env.REACT_APP_DEVX_INFRA_URL} target="_blank" rel="noopener noreferrer">
                                <b>here</b>
                            </a>
                            &nbsp; to explore.
                        </Box>
                    </DevXBanner>
                )} */}
                {isReadOnlyUser() && location.pathname !== '/' && (
                    <Alert severity="warning">
                        <span>This is read only mode access. Please submit a request for our </span>
                        <a href="mailto:DL-ITCMPSERVICES@pepsico.com?subject=CMP Access Request&body=Hi, I would like to access CMP Application to provision cloud resources for application: XXXXXXXXX %0D%0A%0D%0A">
                            DL - IT CMP SERVICES
                        </a>
                        <span> for Server provisioning access</span>
                    </Alert>
                )}
                <Box
                    sx={{ overflow: 'auto', position: 'relative', width: '100%' }}
                    id="applayoutbox"
                    ref={layoutRef}
                    // className="scrollable"
                    className={`scrollable ${isScrolling ? 'scrolling' : ''}`}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onScroll={handleScroll}
                >
                    <Box className="content">
                        <ScrollToTopOnNavigation />
                        {/* {isCMPBetaUser() && (
                            <div style={{ display: 'flex', zIndex: '999', position: 'fixed', bottom: '0px', right: '0px' }}>
                                <ChatbotComponent />
                            </div>
                        )} */}

                        <ModuleRouter />
                        {/* <AppFooter /> */}
                        <AppFooter />
                        <CustomScrollbar Hovered={isHovered} />
                    </Box>
                </Box>

                <Dialog open={open} className="setoverFlow">
                    <DialogContent>
                        <WelcomeDialog handleSupport={handleClose} />
                    </DialogContent>
                    <DialogActions>
                        <CIconButton onClick={() => handleClose()}>
                            <CloseIcon
                                sx={{
                                    '&:hover': {
                                        backgroundColor: 'white',
                                    },
                                }}
                            />
                        </CIconButton>
                    </DialogActions>
                </Dialog>
            </AppWrapper>
        </SessionTimeout>
    );
}

export default withAccessControl(AppLayout);
